import React, { FC } from 'react';
import styles from './NotFoundPage.module.scss';

const NotFoundPage: FC = ({}) => {
  return (
    <div>
      Запрашивамая страница не найдена
    </div>
  )
}

export default NotFoundPage;
